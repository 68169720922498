import axios from 'axios';
import { getDeviceId } from './miscService';
import ErrorReportService from './ErrorReportService';

const buildVersion = process.env.REACT_APP_BUILD_VERSION || 1;
const headers = { deviceId: getDeviceId(), 'build-version': buildVersion };
let baseUrl = process.env.REACT_APP_API_END_POINT || '';
const rollbar = ErrorReportService.getRollbarInstance();

const addRequestInterceptors = () => {
  // Add any interceptor here
  axios.interceptors.request.use(
    config => {
      // DO SOMETHING WITH REQUEST
      return config;
    },
    err => {
      // DO SOMETHING WITH ERROR
      rollbar.error('Err @reqInterceptor: ', { ...err, buildVersion });
      return Promise.reject(err);
    }
  );
};
const addResponseInterceptors = () => {
  axios.interceptors.response.use(
    response => {
      // Do something with response data
      // if (response.data && response.data.action === 'success') {
      //   return response.data;
      // }
      if (response.data && response.data.action === 'failure') {
        console.log('Explicit Failing api from backend');
        rollbar.error('Explicit api failure: ', { ...response, buildVersion });
        return Promise.reject(response.data);
      }
      return response.data;
    },
    error => {
      // Do something with response error
      if (401 === error?.response?.status) {
        window.dispatchEvent(new Event('UNAUTHORIZED_ACCESS'));
      }
      rollbar.error('Err @respInterceptor: ', { ...error, buildVersion });
      return Promise.reject(error.data || error);
    }
  );
};
addRequestInterceptors();
addResponseInterceptors();

const createRequest = config => {
  // Add any common headers and other stuff here
  // Add some base URL here

  return axios({
    ...config,
    headers,
  });
};

const get = (url, params = {}) => {
  const reqConfig = {
    method: 'GET',
    url: baseUrl + url,
    params,
  };
  return createRequest(reqConfig);
};

const post = (url, data = {}, params = {}) => {
  const reqConfig = {
    method: 'POST',
    url: baseUrl + url,
    data,
    params,
  };
  return createRequest(reqConfig);
};

const put = (url, data = {}, params = {}) => {
  const reqConfig = {
    method: 'PUT',
    url: baseUrl + url,
    data,
    params,
  };
  return createRequest(reqConfig);
};

const del = (url, data = {}, params = {}) => {
  const reqConfig = {
    method: 'DELETE',
    url: baseUrl + url,
    data,
    params,
  };
  return createRequest(reqConfig);
};

const postUpload = (url, data = {}, uploadConfig = {}) => {
  const reqConfig = {
    method: 'POST',
    url: baseUrl + url,
    data,
    headers: {
      'content-type': 'multipart/form-data;',
      ...headers,
      ...uploadConfig,
    },
  };
  return axios(reqConfig);
};

export default { get, post, put, del, postUpload };
